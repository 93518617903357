import { Button } from '@mybridge/ui/button';
import { HStack, Stack } from '@mybridge/ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Text } from '@mybridge/ui/text';
import { useContext } from 'react';
import { PostCommentRowContext } from '../../context';

export const PostCommentRowDeleteModal = ({ ...props }) => {
  const { deleteComment, deleteCommentLoading } = useContext(
    PostCommentRowContext
  );

  return (
    <>
      <Modal size="xl" {...props}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
            Delete comment?
          </ModalHeader>
          <ModalBody>
            <Stack>
              <Stack>
                <Text>
                  This can't be undone and it will be removed permanently.
                </Text>
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack justifyContent="flex-end">
              <Button onClick={props?.onClose} variant="secondary">
                Cancel
              </Button>
              <Button
                onClick={deleteComment}
                colorScheme="red"
                isLoading={deleteCommentLoading}
              >
                Remove
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
