import moment from 'moment';
import { useContext } from 'react';
import { PostCommentRowContext } from '../context';
import { Text } from '@mybridge/ui/text';

const PostTiming = () => {
    const { comment } = useContext(PostCommentRowContext);
    const { type, created_at } = comment ?? {};
    if (type === 2) {
        return (
            <Text fontSize="xs" color="brandGray.700">
                {moment(new Date(created_at)).fromNow()}
            </Text>
        );
    }

    return (
        <Text fontSize="xs" color="brandGray.700">
            {moment(created_at).startOf().fromNow()}
        </Text>
    );
};

export default PostTiming;
