import { IconButton } from '@chakra-ui/react';
import { DeleteIcon, EditPostIcon, ThreeDot } from '@mybridge/icons';
// import { IconButton } from "@mybridge/ui/icon-button"
import { Menu, MenuButton, MenuItem, MenuList } from '@mybridge/ui/menu';
import { useContext } from 'react';
import { PostCommentRowContext } from '../context';

export const PostActionsMenu = ({}) => {
  const { comment, setMode, deleteCommentModal } = useContext(
    PostCommentRowContext
  );
  const { author } = comment ?? {};
  return (
    <>
      <Menu>
        <MenuButton as={IconButton} size="xs" variant="ghost">
          <ThreeDot />
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={(e) => setMode('edit')}
            icon={<EditPostIcon width={20} height={20} />}
          >
            Edit Comment
          </MenuItem>
          <MenuItem
            onClick={(e) => deleteCommentModal?.onOpen?.()}
            icon={<DeleteIcon width={20} height={20} />}
          >
            Delete Comment
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
