/* eslint-disable @nx/enforce-module-boundaries */
import { Box, HStack, Stack } from "@mybridge/ui/layout";
import { Link } from "@mybridge/ui/link";
import { SeeMore } from "@mybridge/ui/see-more";
import { Spinner } from "@mybridge/ui/spinner";
import { useContext, useState } from "react";
import { PostCommentRowContext } from "../context";
import { MediaDisplay } from "../media-display";
import { Skeleton } from "@mybridge/ui/skeleton";

// eslint-disable-next-line no-empty-pattern
export const PostCommentRowBody = ({ }) => {
    const { comment, hasInsights, showMedia = true, hasMedia, commentOwner } = useContext(PostCommentRowContext);
    const { body } = comment ?? {};

    const [mediaLoaded, setMediaLoaded] = useState(false);

    const convertToLinks = (text) => {
        const regex = /\{\{(.*?)\}\}/g;
        const parts = [];
        let lastIndex = 0;
      
        text.replace(regex, (match, p1, offset) => {
          // Push the text before the match
          parts.push(text.substring(lastIndex, offset));
          // Push the link
          parts.push(`<a href="/p/${commentOwner?.slug}" target="_blank" rel="noopener noreferrer" style="color:#488FEC; font-weight:600">${p1}</a>`);
          // Update lastIndex
          lastIndex = offset + match.length;
        });
      
        // Push any remaining text after the last match
        parts.push(text.substring(lastIndex));
      
        return parts.join('');
      };
    
    
    //   console.log('commet', convertToLinks(body))

    return <>
        {body?.length && body !== "‏‏‎ ‎"?
            <SeeMore
                color="brandGray.700"
                px={4}
                py={2}
                isInline={true}
                maxLength={350}
                // text={body}
                html={convertToLinks(body)}
                fontSize="md"
            />
            :
            <></>
        }
        <Box  minH={(hasMedia && !mediaLoaded) ? "300px" : "0"} pos="relative">
            {hasMedia && !mediaLoaded ?
                <Skeleton
                    pos="absolute"
                    left="0"
                    right="0"
                    top="0"
                    bottom="0"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Spinner />
                </Skeleton> : ""}
            <MediaDisplay onLoaded={() => setMediaLoaded(true)} />
        </Box>
    </>
}