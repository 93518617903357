import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createContext, useEffect, useMemo, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPostFunc, postDisLikeFunc, postLikeFunc } from 'v4/lib/api/post';
import { useDisclosure } from '@mybridge/ui/hooks';
import {
  deleteComment,
  disLikeComment,
  fetchComments,
  likeComment,
} from 'packages/mybridgev4/store/actions/comment.actions';
import { PostBoxContext } from 'v4/components/post/context';

export const PostCommentRowContext = createContext({});

export const usePostCommentRowContext = (props) => {
  const {
    post,
    comment,
    initialFetchEnabled = false,
    refetch,
    level,
    commentList
  } = props ?? {};
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const [commentState, setCommentState] = useState(comment);
  const [mode, setMode] = useState('view'); // view | edit
  const [commentBoxExpanded, setCommentBoxExapnded] = useState(false);
  const {
    comments
  } = useContext(PostBoxContext);
  
  const deleteCommentModal = useDisclosure();
  const dispatch = useDispatch();
  const { reply_count, like_count, dislike_count, id } = commentState ?? {};

  const { id: uid } = userProfileInfo ?? {};
  const isMyComment = commentState?.owner?.id === userProfileInfo?.id;
  const commentOwner = comment?.comment_replies?.length > 0 ? comment?.owner : null
  useEffect(() => {
    if (
      (comment?.id + '')?.length &&
      JSON.stringify(comment) !== JSON.stringify(commentState)
    ) {
      setCommentState(comment);
    }
  }, [comment]);

  const hasInsights = useMemo(() => {
    return reply_count > 0 || like_count > 0 || dislike_count > 0;
  }, [commentState]);

  const handleDeleteComment = () => {
    const payload = {
      id: commentState?.id,
    };
    mutateDeleteComment(payload);
  };

  // query for liking comment
  const { mutate: mutateDeleteComment, isLoading: deleteCommentLoading } =
    useMutation((body) => dispatch(deleteComment(body)), {
      onSuccess: (data) => {
        refetch?.();
        setCommentState({ ...commentState, removed: true });
      },
      onError: (error) => {
        console.log(error);
      },
    });

  // query for liking comment
  const { mutate: like, isLoading: likeLoading } = useMutation(
    (body) => dispatch(likeComment(body)),
    {
      onSuccess: (data) => {
        refetch();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  // query for disliking comment
  const { mutate: dislike, isLoading: dislikeLoading } = useMutation(
    (body) => dispatch(disLikeComment(body)),
    {
      onSuccess: (data) => {
        refetch();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handleLike = () => {
    const isUserLiked = commentState?.liked_by?.find((el) => el.id === uid);
    if (isUserLiked) {
      like({ id, requestBody: { remove: true } });
    } else {
      like({ id, requestBody: { remove: false } });
    }
  };

  const handleDislike = () => {
    const isUserDisliked = commentState?.dislike_by?.find(
      (el) => el.id === uid
    );
    if (isUserDisliked) {
      dislike({ id, requestBody: { remove: true } });
    } else {
      dislike({ id, requestBody: { remove: false } });
    }
  };
  const { gif, media } = commentState ?? {};
  const hasMedia = gif?.length > 0 || media?.length > 0;

  // console.log('commentList', comments);

  return {
    hasInsights,
    handleDislike,
    handleLike,
    refetch,
    likeLoading,
    dislikeLoading,
    // isLoading: isLoading || isFetching,
    hasMedia,
    comment: commentState,
    level,
    mode,
    setMode,
    setCommentBoxExapnded,
    commentBoxExpanded,
    post,
    isMyComment,
    deleteComment: handleDeleteComment,
    deleteCommentLoading,
    deleteCommentModal,
    commentOwner,
  };
};
